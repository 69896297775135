
// imatge videos

.video-img {
    margin: 0 0 30px 0;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    min-height: 0;
    width: 100%;
    background-color: #000;
    overflow: hidden;
    .play {
    	left: 50%;
	    top: 50%;
	    bottom: auto;
	    right: auto;
        transform: translateX(-50%) translateY(-50%);
        width: 90px;
        height: 90px;
        background-color: transparent;
        background-image: url('../img/play.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #fff;
        position: absolute;
        text-align: center;
        z-index: 30;
    }
    img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        cursor: pointer;
        @include transition($transition-base);
    }
    .title {
    	position: absolute;
    	bottom: 0;
    	left: 0;
    	right: 0;
    	background-color: rgba(255,255,255,0.75);
    	text-align: center;
    	text-transform: uppercase;
    	color: $secondary;
    	z-index: 30;

    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
    }
}

.video-link {
    display: block;
    overflow: hidden;
    .video-img {
        .title {

        }
    }
    &:hover, &:focus {
        display: block;
        img {
            transform: scale(1.1); 
        }
        .video-img {
            .title {
                color: $white;
                background-color: rgba($primary,0.75);
            }
        }
    }
}