




@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamMedium.eot');
    src: url('../fonts/GothamMedium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamMedium.woff2') format('woff2'),
        url('../fonts/GothamMedium.woff') format('woff'),
        url('../fonts/GothamMedium.ttf') format('truetype'),
        url('../fonts/GothamMedium.svg#GothamMedium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Bold';
    src: url('../fonts/GothamBold.eot');
    src: url('../fonts/GothamBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamBold.woff2') format('woff2'),
        url('../fonts/GothamBold.woff') format('woff'),
        url('../fonts/GothamBold.ttf') format('truetype'),
        url('../fonts/GothamBold.svg#GothamBold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Light';
    src: url('../fonts/GothamLight.eot');
    src: url('../fonts/GothamLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamLight.woff2') format('woff2'),
        url('../fonts/GothamLight.woff') format('woff'),
        url('../fonts/GothamLight.ttf') format('truetype'),
        url('../fonts/GothamLight.svg#GothamLight') format('svg');
    font-weight: 300;
    font-style: normal;
}

/*
font-family: 'Roboto', sans-serif;
font-family: 'Kumar One', cursive;
font-family: 'Open Sans', sans-serif;
*/

$font-intro: 'Gotham', sans-serif, cursive;
$font-custom: 'Gotham Bold', sans-serif, cursive;
$font-light :  'Gotham Light', sans-serif, cursive;

$gris: #aaaaaa;
$gris2: #666666;
$gris3:  #f1f1f1;

// Define common padding and border radius sizes and more.
//$primary:  #f1f1f1 !default;
//$secondary: #e59d7b !default;


$primary:  #10604e !default;
$secondary: #fff9ed !default;



$font-family-sans-serif:      'Gotham Light', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;

// Body
//
// Settings for the `<body>` element.

$body-color:  #000000 !default;


// Links
//
// Style anchor elements.

$link-color:     $primary !default;
$link-decoration:   none !default;
$link-hover-color:      $secondary !default;
$link-hover-decoration:  underline !default;



// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;


$blue:    #0061A1 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #0DB02B !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;


$grid-gutter-width:  20px !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;


$headings-font-family:        null !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;

$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;

$display1-weight:             300 !default;
$display2-weight:             300 !default;
$display3-weight:             300 !default;
$display4-weight:             300 !default;

$lead-font-size:              $font-size-base * 1.25 !default;
$lead-font-weight:            300 !default;

$small-font-size:             80% !default;

$text-muted:                  $gray-600 !default;

$blockquote-small-color:      $gray-600 !default;
$blockquote-small-font-size:  $small-font-size !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;

$hr-border-color:             rgba($black, .1) !default;

$mark-padding:                .2em !default;


$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;

$list-inline-padding:         .5rem !default;

$mark-bg:                     #fcf8e3 !default;






// Colors
//
// Define common padding and border radius sizes and more.
$primary:       $green !default;
$secondary:       $blue !default;



// Components
//
// Define common padding and border radius sizes and more.

$border-radius:               0 !default;
$border-radius-lg:            0 !default;
$border-radius-sm:            0 !default;



// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         1.3rem !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      1rem !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;


$btn-padding-y:               0.375rem !default;
$btn-padding-x:               1.5rem !default;


$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;


$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;







// Allows for customizing button radius independently from global border radius
$btn-border-radius:           0 !default;
$btn-border-radius-lg:        0 !default;
$btn-border-radius-sm:        0 !default;


// Breadcrumbs
$breadcrumb-padding-y:              .75rem !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding:           .5rem !default;

$breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg:                     #fff !default;
//$breadcrumb-divider-color:          $gray-600 !default;
//$breadcrumb-active-color:           $gray-600 !default;
$breadcrumb-divider:                quote("/") !default;

//$breadcrumb-border-radius:          $border-radius !default;


// Pagination
$pagination-border-width:           0 !default;







/* owl-carousel */
$owl-color-base:  			$primary !default;
$owl-color-white:           #FFF !default;
$owl-color-gray:            #D6D6D6 !default;

//nav

$owl-nav-color:             $owl-color-white !default;
$owl-nav-color-hover:       $owl-color-white !default;
$owl-nav-font-size:         14px !default;
$owl-nav-rounded:           3px !default;
$owl-nav-margin:            5px !default;
$owl-nav-padding:           4px 7px !default;
$owl-nav-background:        $owl-color-gray !default;
$owl-nav-background-hover:  $owl-color-base !default;
$owl-nav-disabled-opacity:  0.5 !default;

//dots

$owl-dot-width:             10px !default;
$owl-dot-height:            10px !default;
$owl-dot-rounded:           30px !default;
$owl-dot-margin:            5px !default;
$owl-dot-background:        $owl-color-gray !default;
$owl-dot-background-active: $owl-color-base !default;


