/* Cookie bar */
#cookie-law-info-bar {
    padding: 30px 0 !important;
    border-top: 1px solid #DDDDDD !important;
    background-color: #ffffff !important;
    position: fixed !important;
    box-shadow: none !important;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 700;
}
#cookie-law-info-bar p {
    margin: 10px auto;
    display: block;
    clear: both;
    font-size: 14px;
    line-height: 1.5;
}
#cookie-law-info-bar .cookies {
    width: 100%;
    float: left;
}
.cookies .cookies-close {
    padding: 0;
    margin: 30px auto 10px auto;
    display: inline-block;
}
.cookie_action_close_header_reject {
    padding: 6px 30px 8px 30px;
    border: 2px solid #000;
    color: #000 !important;
    background: #f9f9f9 !important;
    margin: 15px  !important;
    border-radius: 0 !important;
    font-size: 16px !important;
    line-height: 30px;
    display: inline-block;
    cursor: pointer;
    @include media-breakpoint-up(sm) {
        margin: 0 15px 15px auto !important;
        display: inline-block;
        padding: 6px 45px 8px 45px;
    }
}
.cookie_action_close_header {
    padding: 6px 30px 8px 30px;
    border: 2px solid #000;
    color: $white !important;
    background: $green !important;
    margin:  15px  !important;
    border-radius: 0 !important;
    font-size: 16px !important;
    line-height: 30px;
    display: inline-block;
    cursor: pointer;
    @include media-breakpoint-up(sm) {
        margin: 0 auto 15px 15px !important;
        display: inline-block;
        padding: 6px 45px 8px 45px;
    }
}
.cookie_action_close_header:hover,
.cookie_action_close_header:focus {
    text-decoration: none;
    background: #fff !important;
    color: #000 !important;
}
#cookie-law-info-bar .cli-plugin-main-link {
    margin: 0;
}