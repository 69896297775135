
/* logo icta */
.icon-logo {
    margin: 10px 0;
    padding: 0;
    text-transform: uppercase;
    float: left;
    display: inline;
    width: 160px;
    height: 90px;
    line-height: inherit;
    white-space: nowrap;
    display: inline-block;
    background: url('../img/logo.png') 0 50% no-repeat;
    background-size: auto 100%;
    &:hover, &:focus {
        text-decoration: none;
    }
}


/* header */
.header {
    background: $secondary;
    .header-top {
        //background: $gray-200;
        .header-grid {
            display: flex !important;
            align-items: center;
            align-content: center;
            justify-content: space-between;
        }
        .navbar-toggler {
            padding: 0;
            border-radius: 0;
            outline: 0;
            span {
                display: table-cell;
                vertical-align: middle;

                em {

                    /* this is the menu icon */
                    display: block;
                    position: relative;
                    height: 3px;
                    width: 30px;
                    background-color: $primary;
                    backface-visibility: hidden;

                    &::after {

                        /* this is the menu icon */
                        display: block;
                        position: relative;
                        height: 3px;
                        width: 30px;
                        background-color: $primary;
                        backface-visibility: hidden;
                    }

                    &::before {

                        /* this is the menu icon */
                        display: block;
                        position: relative;
                        height: 3px;
                        width: 30px;
                        background-color: $primary;
                        backface-visibility: hidden;
                        position: absolute;
                        content: '';
                        left: 0;
                        transition: transform .2s;
                    }

                    /* this is the menu central line */
                    margin: 10px auto;
                    transition: background-color .2s;
                    background-color: rgba(255, 0, 0, 0);

                    &::after {
                        position: absolute;
                        content: '';
                        left: 0;
                        transition: transform .2s;
                    }

                    &::before {

                        /* this is the menu icon top line */
                        transform: rotate(-45deg);
                    }

                    &::after {

                        /* this is the menu icon bottom line */
                        transform: rotate(45deg);
                    }

                }

            }

            &.collapsed span em {

                /* transform menu icon into a 'X' icon */
                background-color: $primary;

                &::before {

                    /* rotate top line */
                    transform: translateY(-10px);
                }

                &::after {

                    /* rotate bottom line */
                    transform: translateY(10px);
                }

            }

            &:hover, &:focus {
                box-shadow: none;
            }
        }
    }
    

    .navbar {
        padding: 0;
        align-items: flex-start;
        font-family: $font-intro;
        @include media-breakpoint-up(lg) {
            align-items: center;
        }
        .social {
            margin-top: 1rem;
            margin-left: auto;
            @include media-breakpoint-up(lg) {
                margin-top: 0;
                margin-left: 15px;
            }

        }
        .languages {
            margin-top: 1rem;
            padding-left: 1rem;
            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
            li {
                padding-left: 0.5rem;
                font-weight: bold;
            }
        }
        a {
            color: $body-color;
            &:hover, &:focus {
                color: $primary !important;
                background-color: transparent;
                text-decoration: none;
            }
        }

    }
    .navbar-nav {
        margin: 15px 0;
        padding: 0;
        //font-family: $font-intro;
   
        @include media-breakpoint-up(lg) {
                margin: 0 0 0 auto;
        }
        > li {
            @include media-breakpoint-up(lg) {
                    float: left;
                    display: inline;
                    position: static;
            }

            > a {
                font-weight: bold;
                
            }
            &.current-menu-item > a {
                color: $body-color !important;
                background-color: transparent;
                text-decoration: none;
                &:hover, &:focus {
                    color: $black !important;
                    background-color: transparent;
                    text-decoration: none;
                }
            }
        }
    }


    &.shrink {
        position:fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1030;
        background: $secondary;
        
    }
  
}

.header-image {
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-color: #eee;
    padding-top: 140px;
}
