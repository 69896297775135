
.projectes {
    .owl-stage-outer {
        padding-bottom: 20px;
    }
    .owl-stage {
        display: flex;
        align-items: stretch !important;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .owl-item {
      
        display: flex;
        @include transition($transition-base);
        &:hover, &:focus {
          
            .novetat {
                .image {
                    .image-bg {
                        transform: scale(1.1); 
                    }
                }
               
            }
            
        }
    }
    
}



.article.archive {
    width: calc(100% - 30px);
    margin: 0 15px 30px 15px;

    @include transition($transition-base);
    @include media-breakpoint-up(sm) {
        width: calc(50% - 30px);
    }
    @include media-breakpoint-up(md) {
        width: calc(50% - 30px);
    }
    @include media-breakpoint-up(lg) {
        width: calc(33.333% - 30px);
    }
   
}



.article {
    width: calc(100% - 30px);
    margin: 0 15px 30px 15px;
    @include transition($transition-base);
    @include media-breakpoint-up(sm) {
        width: calc(50% - 30px);
    }
    @include media-breakpoint-up(md) {
        width: calc(50% - 30px);
    }
    @include media-breakpoint-up(lg) {
        width: calc(50% - 30px);
    }
   
   
    .image {
        margin: 0;
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
        &:before {
            padding-top: 75%;
            display: block;
            content: "";
        }
        .image-bg {
            background-size: cover;
            background-position: center center;
            padding-top: 75%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @include transition($transition-base);

        }
    }

    .content {

        a {
            color: $gris2;
            &:hover, &:focus {
                text-decoration: none;
                color: $secondary;
            }
        }
    }
    .fecha {
        margin-top: auto;
        font-weight: bold;
        text-transform: uppercase;
        small {
            font-weight: bold;
        }
    }
    &:hover, &:focus {
        .image {
            .image-bg {
                transform: scale(1.1); 
            }
        }
        .content {
            //color: $secondary;
            a {
                //color: $secondary;
            }
        }
    }
    

}



.slide-curriculums {
    .owl-stage-outer {
    }
    .owl-stage {
        display: flex;
        align-items: stretch !important;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .owl-item {
        display: flex;
    }
    
}

.flor {
    position: absolute;
    width: 100px;
    height: 100px;
    //background-color: red;
    background-image: url('../img/flor.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    bottom: -35px;
    right: 0;
    img {
        width: 100%;
        height: auto;
    }
}

.curriculum {
    width: 100%;
    .image {
        margin: 0;
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
        &:before {
            padding-top: 75%;
            display: block;
            content: "";
        }
        .image-bg {
            background-size: cover;
            background-position: center center;
            padding-top: 75%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @include transition($transition-base);

        }
    }
    .text {
        position: relative;
        z-index: 99;

    }
    &:hover, &:focus {
        .image {
            .image-bg {
                transform: scale(1.1); 
            }
        }
        .content {
            //color: $secondary;
            a {
                //color: $secondary;
            }
        }
    }
}
