body,
.content {
    overflow-x: hidden !important;
}




h1, .h1 {
    //color: $gray-600;
    font-weight: bold;
    font-size: 2.5rem;
    font-family: $font-intro;
    @include media-breakpoint-up(md) {
        font-size: 2.7rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 3.5rem;
    }
}

b, strong, .font-weight-bold {
    font-family: $font-custom;
}

img {
    max-width: 100%;
    height: auto;
}

.bg {
    background-color: $secondary;
    h1 {
        color: $white;
    }
}

.logo-300 {
    img {
        width: 300px;
        height: auto;
    }

}

.logo-responsive {
    width: 212px;
    height: auto;
}


.page-title {
    min-height: 190px;
    background-size: cover;
    background-color: $gray-500;
    color: $white;
    background-position: center center;
}

.font-intro {
   font-family: $font-intro;
}

.font-light {
    font-family: $font-family-sans-serif;
}


.text-decoration-none {

    &:hover,
    &:focus {
        text-decoration: none !important;
    }
}




.btn {
    width: 100%;
    font-family: $font-intro;
    @include media-breakpoint-up(sm) {
        width: auto;
    }

}

.btn-primary, .btn-primary.disabled, .btn-primary:disabled {
    color: $white;
}

.btn-block {
    width: 100% !important;
}


.section-menu {
    ul {
        line-height: 1.3;
    }
}

.breadcrumb {
    a {
        color: $secondary;
    }
}

.logos {
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img {
        height: 40px;
        width: auto;
        margin: 10px ;
    }
}


.map-item {
    height: 400px;
    width: 100%;
}

.error-404 {
    width: 100%;
    height: 100vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url('../img/404.jpg');
    background-size: cover;
    background-position: center;
}