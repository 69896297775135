.about {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .col-text {
        order: 1;
        @include media-breakpoint-up(sm) {
            width: 50%;
            //padding-bottom: 50%;
        }
    }
    .col-fotos {
        order: 2;
        @include media-breakpoint-up(sm) {
            width: 50%;
        }
    }
    &:nth-child(2n+1) {
        .col-text {
            order: 1;
            @include media-breakpoint-up(sm) {
                width: 50%;
                order: 2;
            }
        }
        .col-fotos {
            order: 2;
            @include media-breakpoint-up(sm) {
                width: 50%;
                order: 1;
            }
        }
    }
}
.galeria {
    .foto {
        width: 100%;
        height: 0;
        padding-bottom: 60%;
        background-size: cover;
        background-position: center;
    }
    .owl-dots {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        .owl-dot span {
            border: 2px solid #fff;
            background: transparent;
        }
    }
    .owl-dots .owl-dot.active span,
    .owl-dots .owl-dot:hover span {
        background: #fff;
    }
    .owl-nav [class*='owl-'] {
        color: #fff;
    }
    .owl-nav .owl-prev {
        left: 15px;
    }
    .owl-nav .owl-next {
        right: 15px;
    }
}