// Share

.card__share {

    position: relative;

}

.card__social {
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    width: 160px;
    transform: translateZ(0);
    transform: translateX(0px);
    transition: transform 0.35s ease;
}

.card__social--active {
    visibility: visible;
    /*z-index: 3;*/
    transform: translateZ(0);
    transform: translateX(-48px);
    transition: transform 0.35s ease;
}

.share-toggle {
    z-index: 2;
}

.share-toggle:before {
    content: "\f1e0";
    font-family: 'FontAwesome';
    color: #3498db;
}

.share-toggle.share-expanded:before {
    content: "\f00d";
}

.share-icon {
    display: inline-block;
    width: 55px;
    height: 55px;
    line-height: 55px;

    text-align: center;
    border-radius: 50%;
    background-color: #f1f1f1;
    color: $black;
    transition: all 0.3s ease;
    outline: 0;
    margin: 0 0 0 5px;

    font-size: 1.5rem;
 
}

.share-icon:hover,
.share-icon:focus {
    text-decoration: none;
    transform: scale(1.1);
    color: $black;
}

.share {
    position: relative;
    
    ul {
        padding: 0 10px;
        margin: 0 auto;
        list-style: none;
        background-color: $white;
        position: relative;
        z-index: 90;
        display: inline-block;
        li {
            padding: 0;
            margin: 0;
            display: inline-block;
        }
    }
}
.share:after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    height: 1px;
    background: #DEDEDE;
    display: block;
    top: 49%;
    z-index: 1;
}

.share-icon-sm {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.3s ease;
    outline: 0;
    margin: 0;
    position: relative;
}

.share-icon-sm:hover,
.share-icon-sm:focus {
    text-decoration: none;


    transform: scale(1.1);
}

.facebook {
    background-color: #3b5998;
}

.twitter {
    background-color: #00abe3;
}

.googleplus {
    background-color: #d3492c;
}

.linkedin {
    background-color: #2274b9;
}

.whatsapp {
    background-color: #69dc7e;
}

.facebook,
.twitter,
.googleplus,
.linkedin,
.whatsapp {
    color: #fff;
}

.facebook:hover,
.twitter:hover,
.googleplus:hover,
.linkedin:hover,
.whatsapp:hover {
    color: #eee;
}


.social {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
        justify-content: flex-end;
        padding-left: 30px;
    }
    li {
        padding: 0.5rem;
    }
}