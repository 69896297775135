/*------------------------------------*\
  PRELOADER
\*------------------------------------*/
.slider-preloader {
    margin: 0;
    width: 100% !important;
    float: left;
    position: relative;
    overflow: hidden;
    clear: both;
    z-index: 800;
    min-height: 100vh;
}
.preloader-slider{
    position: absolute;
    top:0;
    left:0;
    bottom: 0;
    right: 0;
    background: transparent;
}
.preloader-slider .preloader-gif{
    border: 5px solid #f3f3f3; 
    border-top: 5px solid $primary; 
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
// Slide principal
.slide-principal2 {
    margin-top: 0;
    .slide {
        width: 100%;
        background-size: cover;
        background-position: center center;
        color: #fff;
        height: 100vh;
        min-height: 480px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .cover {
       
        width: 100%;
        position: relative;
        
    }
    .container {}
    .owl-dots {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        .owl-dot span {
            border: 2px solid #fff;
            background: transparent;
        }
    }
    .owl-dots .owl-dot.active span, .owl-dots .owl-dot:hover span {
        background: #fff;
    }
    .owl-nav [class*='owl-'] {
        color: #fff;
    }
    .owl-nav .owl-prev {
        left: 15px;
    }
    .owl-nav .owl-next {
        right: 15px;
    }
    
}

// Slide principal
.slide-secundari {
    margin-top: 0;
    .slide {
        width: 100%;
        background-size: cover;
        background-position: center center;
        color: #fff;
        min-height: 480px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .cover {
       
        width: 100%;
        position: relative;
        
    }
    .container {}
    
    .owl-dots {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        .owl-dot span {
            border: 2px solid #fff;
            background: transparent;
        }
    }
    .owl-dots .owl-dot.active span, .owl-dots .owl-dot:hover span {
        background: #fff;
    }
    .owl-nav [class*='owl-'] {
        color: #fff;
    }
    .owl-nav .owl-prev {
        left: 15px;
    }
    .owl-nav .owl-next {
        right: 15px;
    }
    
}
/*------------------------------------*\
  TEXT
\*------------------------------------*/
.slide-text {
        text-align: left;
        width: 100%;
        padding-bottom: 4rem;
        h2, .h2{
            font-size: 2.5rem;
            margin: 0;
            font-weight: normal;
            line-height: 1.1;
            font-family: $font-family-sans-serif;
            @include media-breakpoint-up(md) {
                font-size: 3rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 4rem;
            }
        }
        .btn {
            margin: 15px auto 15px auto;
            text-transform: uppercase;
        }
    }
.intro {
    position: relative;
    .text {
        width: 100%;
        //background: rgba(0,0,0,0.5);
        color: #fff;
        bottom: 0;
        left: 0;
        right: 0;
        padding-top: 160px;
        padding-bottom: 90px;
        position: absolute;
        z-index: 999;
    }
    .title {
        font-family: $font-family-base;
        font-size: 3rem;
        color: #fff;
        @include media-breakpoint-up(md) {
            font-size: 4rem;
        }
        @include media-breakpoint-up(xl) {
            font-size: 6rem;
        }
    }
}
/*------------------------------------*\
  Slide principal
\*------------------------------------*/
.slide-principal {
    .slide {
        width: 100%;
        background-size: cover;
        background-position: center center;
        color: #fff;
        height: 100vh;
        min-height: 480px;
    }
    .slide-text {
        display: table-cell;
        vertical-align: bottom;
    }
    .owl-dots {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        .owl-dot span {
            border: 2px solid #fff;
            background: transparent;
        }
    }
    .owl-dots .owl-dot.active span, .owl-dots .owl-dot:hover span {
        background: #fff;
    }
    .owl-nav [class*='owl-'] {
        color: #fff;
    }
    .owl-nav .owl-prev {
        left: 15px;
    }
    .owl-nav .owl-next {
        right: 15px;
    }
}
.galeria-fotos {
    .owl-dots {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        .owl-dot span {
            border: 2px solid #fff;
            background: transparent;
        }
    }
}