/*
 *  Green theme - Owl Carousel CSS File
 */

$owl-color-base:            #0DB02B !default;
$owl-color-white:           #FFF !default;
$owl-color-gray:            #D6D6D6 !default;

//nav

$owl-nav-color:             $owl-color-white !default;
$owl-nav-color-hover:       $owl-color-white !default;
$owl-nav-font-size:         14px !default;
$owl-nav-rounded:           3px !default;
$owl-nav-margin:            5px !default;
$owl-nav-padding:           4px 7px !default;
$owl-nav-background:        $owl-color-gray !default;
$owl-nav-background-hover:  $owl-color-base !default;
$owl-nav-disabled-opacity:  0.5 !default;

//dots

$owl-dot-width:             10px !default;
$owl-dot-height:            10px !default;
$owl-dot-rounded:           30px !default;
$owl-dot-margin:            5px 7px !default;
$owl-dot-background:        $owl-color-gray !default;
$owl-dot-background-active: $owl-color-base !default;


.owl-carousel {
 
    .owl-nav {
        margin-top: 0;
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        [class*='owl-'] {
            color: #B6B5B5 !important;
            //font-size: $owl-nav-font-size;
            display: inline-block;
            cursor: pointer;
            border-radius: 0;
            width: 40px;
            height: 40px;
            line-height: 40px !important;
            font-size: 40px !important;
            text-align: center;
            position: absolute;
            top: 50%;
            margin: -8px 0 0 0;
            background: transparent;
            &:hover, &:focus {
                color: $secondary !important;
                text-decoration: none;
                outline: none;
            }
            i {
             
            }
            
        }
        .owl-prev {
            left: 0;
            @include media-breakpoint-up(md) {
                left: -50px;
            }
        }
        .owl-next {
            right: 0;
            @include media-breakpoint-up(md) {
                right: -50px;
            }
        }
        .disabled {
            opacity: $owl-nav-disabled-opacity;
            cursor: default;
        }
    }

    // Styling dots
    .owl-nav.disabled + .owl-dots {
        margin-top: 10px;
    }

    .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline;

            span {
                width: $owl-dot-width;
                height: $owl-dot-height;
                margin: $owl-dot-margin;
                background: $owl-dot-background;
                display: block;
                -webkit-backface-visibility: visible;
                transition: opacity 200ms ease;
                border-radius: 50%;
            }

            &.active,
            &:hover, &:focus {
                outline: none;
                span {
                    background: $owl-dot-background-active;
                }
            }
        }
    }
}
