.novetats {
    .owl-stage-outer {
        padding-bottom: 20px;
    }

    .owl-stage {
        display: flex;
        align-items: stretch !important;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .owl-item {

        display: flex;
        @include transition($transition-base);

        &:hover,
        &:focus {

            .novetat {
                .image {
                    .image-bg {
                        transform: scale(1.1);
                    }
                }

            }

        }
    }

}

.novetat {
    width: 100%;
    margin: 0;


    .image {
        margin: 0;
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;

        &:before {
            padding-top: 75%;
            display: block;
            content: "";
        }

        .image-bg {
            background-size: cover;
            background-position: center center;
            padding-top: 75%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @include transition($transition-base);

        }
    }

    .content {

        a {
            color: $black;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    .fecha {
        margin-top: auto;
    }

}

.fecha {

    text-transform: uppercase;
    font-weight: bold;

    small {
        font-weight: bold;
    }
}


.aligncenter {
    margin: 0 auto 1rem auto;
    text-align: center;
    width: 100%;
    height: auto;
}

.alignleft {
    float: left;
    margin: 5px 30px 30px 0;
    width: 50%;
    height: auto;
}

.alignright {
    float: right;
    margin: 5px 0 30px 30px;
    width: 50%;
    height: auto;
}



.tags {
    margin-top: auto;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {

        display: inline-block;
        font-size: 12px;
        margin: 0 10px 10px 0;

        a {
            padding: 6px 10px 5px 10px;
            background: $primary;
            color: #fff;
            text-transform: uppercase;
            display: block;
            @include transition($transition-base);

            &:hover,
            &:focus {
                text-decoration: none;
                background: $gray-600;
            }
        }
    }
}



.relacionados {
    .owl-stage {
        display: flex;
        align-items: stretch !important;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .owl-item {
        display: flex;
    }
    .owl-dots {
        margin-top: 2rem;
    }
    .projecte {
        width: 100% !important;
        margin: 0 !important;

       
    }
}



// Page Navi
#browsing {
    clear: both;
}

.wp-pagenavi {
    margin: 0 0 66px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 30px;
        height: 30px;
        line-height: 1;
        margin: 0 5px;
        background-color: #f8f9fa;
        color: #6c757d;
        border: 1px solid #f8f9fa;
        border-radius: 3px;
        overflow: hidden;
        &:hover, &:focus {
            text-decoration: none;
            background-color:  $secondary;
            color: #fff;
        }

    }
    .extend {
        margin: 0 5px;
    }


    .current {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 30px;
        height: 30px;
        background-color: $secondary;
        color: #fff;
        border: 1px solid $secondary;
        border-radius: 3px;
        overflow: hidden;
    }

    .previouspostslink {
        margin: 0;
        border-radius: 50%;
    }

    .nextpostslink {
        margin: 0;
        border-radius: 50%;
    }

}