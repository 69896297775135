.footer-bg {

    .menu-footer {
        @include media-breakpoint-up(md) {
            display: flex;
        }
    }
    .menu {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        @include media-breakpoint-up(md) {
            justify-content: flex-end;
            margin-left: auto;
        }
        li {
            padding: 0.5rem;
            
        }
    }

    .roe-img {
        width: 200px;
        margin: 0 auto;
        @include media-breakpoint-up(md) {
            margin: 0;
        }
    }


    a {
        color: $white;
        &:hover, &:focus {
            color: #333;
            text-decoration: none;
        }
    }
}


/* menu-footer */
.footer {
    background: #333;
    color: #fff;
    a {
        color: #fff;
        margin-bottom: 1rem;
    }
    ul {
        li {
           padding: 0 0.5rem;
        }
    }
    .logo {
        font-size: 30px;
        @include media-breakpoint-up(md) {
            font-size: 40px;
        }
    }
}


